<template>
  <div>
    <div class="admin-dashboard">
      <PageHeader :title="$t('dashboard')" />
      <v-row class="pt-5">
        <v-col cols="6">
          <v-card flat class="pa-8">
            <v-card-title class="pa-0 pb-4">
              {{ $t("bulkUploadUsers") }}
            </v-card-title>
            <v-file-input
              v-model="csv"
              :placeholder="`${$t('upload')} CSV`"
              outlined
              color="#0253B3"
              :prepend-icon="null"
              prepend-inner-icon="mdi-cloud-upload"
              class="file-input-jonder "
              accept=".csv"
              @change="(e) => (csv = e)"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary"> {{ text }} </v-chip>
              </template>
            </v-file-input>
            <response-alert :response="response"></response-alert>
            <div class="d-flex justify-end">
              <v-btn
                depressed
                color="primary"
                class="px-10"
                height="48"
                @click="importCSV"
                >{{ $t("save") }}
              </v-btn>
            </div>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card flat class="pa-8">
            <v-card-title class="pa-0 pb-4">
              {{ $t("bulkUploadJobBranches") }}
            </v-card-title>
            <v-file-input
              v-model="csv_job_branches"
              :placeholder="`${$t('upload')} CSV`"
              outlined
              color="#0253B3"
              :prepend-icon="null"
              prepend-inner-icon="mdi-cloud-upload"
              class="file-input-jonder "
              accept=".csv"
              @change="(e) => (csv_job_branches = e)"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary"> {{ text }} </v-chip>
              </template>
            </v-file-input>
            <response-alert :response="response_job"></response-alert>
            <div class="d-flex justify-end">
              <v-btn
                depressed
                color="primary"
                class="px-10"
                height="48"
                @click="importCSVJOBBranches"
                >{{ $t("save") }}
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card flat class="pa-8">
            <div class="d-flex justify-space-between mb-3">
              <div>
                <v-card-title class="pa-0 pb-4">
                  {{ $t("duplicatedUsersManagement") }}
                </v-card-title>
              </div>
              <div>
                <v-btn
                  depressed
                  color="primary"
                  class="px-10"
                  height="48"
                  @click="$store.dispatch('admin/checkDuplicateUsers')"
                >
                  {{ $t("refresh") }}
                </v-btn>
                <v-btn
                v-if="usersSelected.length > 0"
                depressed
                color="primary"
                class="px-10"
                height="48"
                v-on:click="removeSelectedUsers"
              >
                Remove selected
              </v-btn>
              </div>
            </div>
            <div>
              <v-data-table
                :headers="duplicateUsers.headers"
                :items="$store.getters['admin/duplicateUsers']"
                :server-items-length="
                  $store.getters['admin/duplicateUsersMeta'].total
                "
                :items-per-page.sync="duplicateUsers.params.per_page"
                :page.sync="duplicateUsers.params.page"
                @update:page="fetchDuplicateUsers()"
                @update:items-per-page="fetchDuplicateUsers()"
                v-model="usersSelected"
                :single-select="false"
                show-select
              >
                <template v-slot:item.action="{ item }">
                  <v-icon
                    small
                    @click="$store.dispatch('admin/deleteUser', item.id)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card flat class="pa-8">
            <div class="d-flex justify-space-between mb-3">
              <div>
                <v-card-title class="pa-0 pb-4">
                  {{ $t("duplicatedJobBranchesManagement") }}
                </v-card-title>
              </div>
              <div>
                <v-btn
                  depressed
                  color="primary"
                  class="px-10"
                  height="48"
                  @click="$store.dispatch('admin/checkDuplicateJobBranches')"
                >
                  {{ $t("refresh") }}
                </v-btn>
                <v-btn
                  v-if="branchesSelected.length > 0"
                  depressed
                  color="primary"
                  class="px-10"
                  height="48"
                  v-on:click="removeSelectedBranches"
                >
                  Remove selected
                </v-btn>
              </div>
            </div>
            <div>
              <v-data-table
                v-model="branchesSelected"
                :headers="duplicateJobBranches.headers"
                :items="$store.getters['admin/duplicateJobBranches']"
                :server-items-length="
                  $store.getters['admin/duplicateJobBranchesMeta'].total
                "
                :items-per-page.sync="duplicateJobBranches.params.per_page"
                :page.sync="duplicateJobBranches.params.page"
                @update:page="fetchDuplicateJobBranches()"
                @update:items-per-page="fetchDuplicateJobBranches()"
                :single-select="false"
                show-select
              >
                <v-btn
                  v-if="branchesSelected.length > 0"
                  color="primary"
                  dark
                  v-on:click="removeSelectedBranches"
                >
                  Remove selected
                </v-btn>
                <template v-slot:item.action="{ item }">
                  <v-icon
                    small
                    @click="$store.dispatch('admin/deleteJobBranche', item.id)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <DeleteConfirmationDialog
        :dialogDeleteSelected="dialogDeleteSelectedBranches"
        @delete-confirm="deleteSelectedBranches"
        :dialogTitle="$t('Are you sure?')"
        :cancelText="$t('Cancel')"
        :okText="$t('Delete')"
        @close-dialog="dialogDeleteSelectedBranches = false"
      />
      <DeleteConfirmationDialog
        :dialogDeleteSelected="dialogDeleteSelectedUsers"
        @delete-confirm="deleteSelectedUsers"
        :dialogTitle="$t('Are you sure?')"
        :cancelText="$t('Cancel')"
        :okText="$t('Delete')"
        @close-dialog="dialogDeleteSelectedUsers = false"
      />
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/admin/PageHeader";
import ResponseAlert from "@/components/ResponseAlert";
import DeleteConfirmationDialog from "@/components/common/DeleteConfirmationDialog";
import store from "@/store";

export default {
  name: "AdminDashboard",

  components: {
    PageHeader,
    ResponseAlert,
    DeleteConfirmationDialog,
  },

  data() {
    return {
      branchesSelected: [],
      usersSelected: [],
      csv: null,
      csv_job_branches: null,
      response: {},
      response_job: {},
      dialogDeleteSelectedBranches: false,
      dialogDeleteSelectedUsers: false,
      duplicateUsers: {
        params: {
          page: 1,
          per_page: 10,
          user_type: "jobseeker",
        },
        headers: [
          {
            text: "ID",
            value: "id",
            width: "5%",
            sortable: false,
          },
          {
            text: this.$t("firstName"),
            value: "first_name",
            width: "25%",
            sortable: false,
          },
          {
            text: this.$t("lastName"),
            value: "last_name",
            width: "25%",
            sortable: false,
          },
          {
            text: this.$t("email"),
            value: "email",
            width: "20%",
            sortable: false,
          },
          {
            text: this.$t("type"),
            value: "role",
            width: "20%",
            sortable: false,
          },
          {
            text: this.$t("delete"),
            value: "action",
            width: "5%",
            sortable: false,
          },
        ],
      },
      duplicateJobBranches: {
        params: {
          page: 1,
          per_page: 10,
          type: "job_branches",
        },
        headers: [
          {
            text: "ID",
            value: "id",
            width: "5%",
            sortable: false,
          },
          {
            text: "EN",
            value: "en",
            width: "30%",
            sortable: false,
          },
          {
            text: "DE",
            value: "de",
            width: "30%",
            sortable: false,
          },
          {
            text: "FR",
            value: "fr",
            width: "30%",
            sortable: false,
          },
          {
            text: this.$t("delete"),
            value: "action",
            width: "5%",
            sortable: false,
          },
        ],
      },
    };
  },

  methods: {
    removeSelectedBranches: function() {
      this.dialogDeleteSelectedBranches = true;
    },
    removeSelectedUsers: function() {
      this.dialogDeleteSelectedUsers = true;
    },
    deleteSelectedBranches() {
      this.dialogDeleteSelectedBranches = false;
      // $store.dispatch('admin/deleteUser', item.id)
      let branchesSelected = [];
      this.branchesSelected.map((e) => {
        branchesSelected.push(e.id);
      });

      store.dispatch("admin/cmsDeleteSelectedListItem", {
        items: branchesSelected,
        params: this.duplicateJobBranches.params,
      });
      this.fetchDuplicateJobBranches();
      this.branchesSelected = [];
    },
    deleteSelectedUsers() {
      this.dialogDeleteSelectedUsers = false;
      let selected = [];
      this.usersSelected.map((e) => {
        selected.push(e.id);
      });

      store.dispatch("admin/userDeleteSelectedListItem", {
        items: selected,
        params: this.duplicateUsers.params,
      });

      this.fetchDuplicateUsers();
      this.usersSelected = [];
    },
    importCSV() {
      this.$store
        .dispatch("admin/importUsers", this.csv)
        .then((res) => {
          this.response = res.data;
          this.$store.dispatch("admin/fetchJobseekers");
          this.$store.dispatch("admin/fetchCompanies");
        })
        .catch((err) => {
          this.response = err.data;
        });
    },
    importCSVJOBBranches() {
      this.$store
        .dispatch("admin/importJobBranches", this.csv_job_branches)
        .then((res) => {
          this.response_job = res.data;
          this.$store.dispatch("admin/fetchJobseekers");
          this.$store.dispatch("admin/fetchCompanies");
        })
        .catch((err) => {
          this.response_job = err.data;
        });
    },
    fetchDuplicateUsers() {
      this.$store.dispatch(
        "admin/checkDuplicateUsers",
        this.duplicateUsers.params
      );
    },
    fetchDuplicateJobBranches() {
      this.$store.dispatch(
        "admin/checkDuplicateJobBranches",
        this.duplicateJobBranches.params
      );
    },
  },
};
</script>
