<template>
  <div>
    <v-dialog v-model="dialogDeleteSelected" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">{{ dialogTitle }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDeleteSelected">{{
            cancelText
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="confirmDelete">{{
            okText
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogDeleteSelected: Boolean,
    dialogTitle: String,
    cancelText: String,
    okText: String,
  },
  methods: {
    closeDeleteSelected() {
      this.$emit("close-dialog");
    },
    confirmDelete() {
      this.$emit("delete-confirm");
    },
  },
};
</script>
